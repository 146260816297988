<template>
  <section>
    <h2 class="recruit-contact-title">求人への応募・お問い合わせ</h2>
    <div class="wrap">
      <p>
        求人へのご応募は、以下のボタンを押して、お問い合わせフォーム、または、お電話にてお願いいたします。選考について案内させていただきます。求人について知りたいことがあるなど、ちょっとしたことでも構いません。ぜひお気軽にご応募・お問い合わせをお願いいたします。
      </p>
      <div class="contact-btn">
        <a class="contact-link" href="/#contact">求人の応募・お問い合わせ</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RecruitContact",
}
</script>

<style lang="scss" scoped>
  section {
    padding-top: 0;
    .recruit-contact-title {
        font-size: 25px;
        font-weight: 500;
        line-height: 5rem;
        color: var(--white);
        background-color: var(--main);
        @media screen and (max-width: 767px) {
          font-size: 23px;
          line-height: 4rem;
        }
    }
    .wrap {
      p {
        margin: 20px 0;
      }
      .contact-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        @media screen and (max-width: 767px) {
          padding-top: 10px;
        }
        .contact-link {
          width: 40%;
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          padding: 20px 0;
          border: 1px solid var(--font);
          color: var(--font);
          background-color: var(--white);
          cursor: pointer;
          @media screen and (max-width: 767px) {
            width: 85%;
          }
          &:hover {
            border: 1px solid var(--main);
            color: var(--white);
            background-color: var(--main);
          }
        }
      }
    }
  }
</style>